export const organization = {
  url: "https://playcutegames.com",
  logo: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
  name: "Play Cute Games",
  description: "Play Cute Games offers a wide range of fun and engaging online games for all ages.",
  email: "admin@fabboxstudios.com",
  telephone: "+91-9080449971",
  sameAs: [
    "https://facebook.com/playcutegames",
  ],
  address: {
    streetAddress: "12, Ground Floor, Radha Building, 1st Main Rd, Mangla Nagar, Sakthi Nagar,",
    addressLocality: "Porur, Chennai",
    addressRegion: "Tamilnadu",
    postalCode: "600116",
    addressCountry: "IN",
  },
  vatID: "",
  iso6523Code: "",
};

export const websiteSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "name": "Playcutegames",
  "alternateName" : "Play Cute Games",
  "url": "https://playcutegames.com/",
};